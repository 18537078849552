:root {
  --color-background: #FAFAFA;
  --color-background-paper: #FFFFFF;

  --color-primary-light: #E14D53;
  --color-primary: linear-gradient(90deg, #E0055B 0%, #570376 100%);
  --color-primary-dark: linear-gradient(90deg, #E1015C, #570078);

  --color-secondary-light: #FFFFFF;
  --color-secondary: #FFFFFF;
  --color-secondary-dark: #FFFFFF;

  --color-success-light: #62B18C;
  --color-success: #1BF554;
  --color-success-dark: #00A82C;

  --color-error-light: #FF6B6B;

  --text-color-primary: #333333;
  --text-color-primary-dark: #2A2A2A;
  --text-color-primary-contrast: #FFFFFF;
  --text-color-secondary: #6B788B;
  --text-color-subhead: #E1015C;
  --text-color-subhead-dark: #E1015C;

  --color-icon-primary-disabled: #FFFFFF;
  --color-icon-primary: #E1015C;
  --color-icon-primary-contrast: #FFFFFF;
  --color-icon-primary-dark: #570078;
  --color-icon-disabled: #6B788B;
  --color-icon-bright: #333333;

  --color-border-card: #C3C3C3;
  --color-border-primary: #E1015C;

  --color-background-pagination-step: #D0D0D0;

  --color-gray-400: #BDBDBD;
  --color-gray-500: #9E9E9E;
  --color-gray-700: #616161;

  --color-gradient: linear-gradient(180deg, #570376 2.03%, #e0055b 79.5%);

  // Adding the correct color to components such as IonAlert
  --ion-color-primary: var(--text-color-subhead);
  --ion-text-color: var(--text-color-primary);
  --ion-background-color: var(--color-background-paper);
}

