.input {
  height: 4rem;
  width: 100%;

  padding: 2rem 1rem;

  font-size: 1.3rem;
  color: var(--text-color-primary);
  text-align: left;

  border: 1px solid var(--color-border-card);
  border-radius: 8px;

  background: var(--color-background-paper);
  -webkit-appearance: none;

  &__rounded {
    @extend .input;

    padding: 2rem;

    border: .1rem solid var(--color-border-card);
    border-radius: 5rem;
  }
}

.input-with-suffix {
  position: relative;
  align-items: center;

  display: flex;
  justify-content: center;
  color: darkslategray;

  > input {
    padding-right: 6rem;
  }

  button {
    position: absolute;
    right: 1.5rem;
    font-size: 1.8rem;
    opacity: 0.6;

    background: var(--color-background-paper);
    box-shadow: 0 .2rem .7rem rgba(0, 0, 0, 0.25);

    ion-icon {
      color: black;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }
  }
}
